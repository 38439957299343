import React, { useCallback, useEffect, useReducer, useState } from 'react'
// import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { Alert, Button, Form, Layout, message, PageHeader, Row, Spin, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import ErrorBlock from '../../../components/Partner/Profile/ErrorBlock'
import { AppConfig, cardStatusCodes, partnerStatusCodes } from '../../../AppConfig'
import {
  BirthDateFormItem,
  CityFormItem,
  NameFormItem,
  PassportBlock,
  PlaceOfBirthFormItem,
  RegistrationAddressBlock,
  StoreChainFormItem,
  StoreFormItem
} from '../../../components/Common'
import { errors, profileContractScreen, profileScreen } from '../../../translates'
import {
  createApiDataFromForm,
  createFormDataFromApi,
  simpleReducer,
  validateAllPhotosUploading,
  validateName
} from '../../../helper'
import MiddleNameFormItem from '../../../components/Common/FormItems/MiddleNameFormItem'
import './ProfileEditInfoScreenStyle.css'
import '../../../components/Partner/Profile/ProfileStyle.css'
import { LeftOutlined } from '@ant-design/icons'
import { useStores } from '../../../Store/Store'
import {
  useGetEditCardForm, useGetInitialEditCardForm,
  useMutatePostCardForm
} from '../../../api/react-query/partners'
import { formLayout } from '../../../Constants'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import Documents from '../../../components/Partner/Profile/Documents'
import { PROFILE_CARD_STATE_INIT } from './ProfileMainScreen'
import ExistsNfc from '../../../components/Partner/Profile/ExistsNfc'
import CheckingData from '../../../components/Partner/CheckingData'

const { Content } = Layout
const { Title, Text } = Typography

function ProfileEditCardScreen ({ extState, setExtState }) {
  const {
    partnerProfile: {
      profile,
      profile: {
        cardFormStatus
      },
      setPartnerProfile
    }
  } = useStores()
  const history = useHistory()
  const [form] = Form.useForm()
  const [isTouched, setIsTouched] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})
  const [trigger, setTrigger] = useState(false)
  const [internalState, setInternalState] = useReducer(simpleReducer, {
    city: null,
    storeChain: null,
    store: null,
    storeId: null,
    existsNfc: false,
    registrationKindCode: null
  })

  const {
    data: editCardFormData
  } = useGetEditCardForm({
    enabled: [cardStatusCodes.verification, cardStatusCodes.declined, cardStatusCodes.filling, cardStatusCodes.accepted].includes(cardFormStatus)
  })

  const {
    data: initialCardFormData
  } = useGetInitialEditCardForm({
    enabled: cardFormStatus === cardStatusCodes.canCreate || cardFormStatus === cardStatusCodes.none
  })

  const { mutate, data, isLoading, isSuccess, isError } = useMutatePostCardForm()

  const handleUpdateForm = useCallback((isFirstLaunch = false) => {
    if (!isTouched && isFirstLaunch !== true) {
      setIsTouched(true)
    }

    const values = form.getFieldsValue()
    setInternalState({ registrationKindCode: values?.personalData?.registrationKind })
  }, [isTouched])

  const fillForm = useCallback((formData) => {
    if (Object.keys(formData?.form || {}).length && !form.getFieldValue(['personalData', profileScreen.input.firstName.name])?.length) {
      const store = {
        city: formData.form.store?.city,
        storeChain: formData.form.store?.chain,
        store: formData.form.store?.name,
        storeId: formData.form.store?.id,
        existsNfc: formData.form.hasNfc,
        registrationKindCode: formData.form.registrationKindCode
      }
      let data = createFormDataFromApi({
        personalData: { ...formData.form, ...store },
        passportPhoto1: formData.form.passportPhoto1,
        passportPhoto2: formData.form.passportPhoto2,
        passportPhotoExtraReg: formData.form.passportPhotoExtraReg,
        passportPhotoTmpReg: formData.form.passportPhotoTmpReg,
        passportPhoto3: formData.form.passportPhoto3
      })

      data = {
        ...data,
        personalData: {
          ...data?.personalData,
          registrationKind: data?.personalData?.registrationKindCode,
          registrationAddress: {
            ...data?.personalData?.registrationAddress,
            country: data?.personalData?.registrationAddress?.country ?? profileScreen.input.registrationAddresscountry.placeholder
          }
        }
      }

      form.setFieldsValue(extState?.form ? extState.form : data)
      setInternalState(extState?.storeData ? extState.storeData : store)
      setTrigger(true)
    }
  }, [form, extState])

  const handleChangeCity = () => {
    const values = form.getFieldsValue()
    const store = {
      city: values.personalData.city,
      storeChain: '',
      store: '',
      storeId: null
    }
    form.setFieldsValue({ ...values, personalData: { ...values.personalData, ...store } })
    setInternalState(store)
  }

  const handleChangeChain = () => {
    const values = form.getFieldsValue()
    const store = {
      storeChain: values.personalData.storeChain,
      store: '',
      storeId: null
    }
    form.setFieldsValue({ ...values, personalData: { ...values.personalData, ...store } })
    setInternalState(store)
  }

  const handleChangeStore = (item) => {
    const values = form.getFieldsValue()
    const store = {
      store: values.personalData.store,
      storeId: item?.id
    }
    setInternalState(store)
  }

  useEffect(() => {
    fillForm(editCardFormData)
  }, [editCardFormData, fillForm])

  useEffect(() => {
    fillForm(initialCardFormData)
    handleUpdateForm()
  }, [fillForm, handleUpdateForm, initialCardFormData])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка отправки реквизитов.')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      if (data?.errorMessage) {
        return message.error(data?.errorMessage || 'Ошибка отправки реквизитов.')
      }
      if (Object.keys(data?.profile || {}).length) {
        setPartnerProfile({ profile: data.profile })
      }
      message.success('Ваш запрос отправлен на проверку.')
      setExtState(PROFILE_CARD_STATE_INIT)
    }
  }, [data, isSuccess, setExtState])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors])

  const onFinish = (values) => {
    if (![cardStatusCodes.canCreate, cardStatusCodes.declined].includes(profile?.cardFormStatus)) {
      return
    }
    setBackendFieldsErrors({})
    if (!validateAllPhotosUploading(values)) {
      message.error(errors.documentsUploading)
      return
    }
    if (!internalState.existsNfc) {
      return
    }
    form.validateFields().then(values => {
      delete values.personalData.city
      delete values.personalData.storeChain
      delete values.personalData.store
      const data = createApiDataFromForm(values)
      mutate({
        data: {
          ...data,
          ...data.personalData,
          storeId: internalState.storeId,
          ...(internalState.existsNfc && { hasNfc: internalState.existsNfc })
        }
      })
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  if (profile?.statusCode && profile?.statusCode !== partnerStatusCodes.loginIssued) {
    history.push(PROFILE_MAIN_PAGE)
  }
  const handleUpdateImage = (item, info) => { form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: info.fileList }); setTrigger(true) }
  const handleDeleteImage = (item) => { form.setFieldsValue({ ...form.getFieldsValue(), [item.name]: [] }); setTrigger(true) }

  const handleChangeNfc = (checked) => {
    setInternalState({ existsNfc: checked })
  }

  return (
    <Layout className='ProfileEditInfoWrapper'>
      {/* <MobileHeader /> */}

      <Spin spinning={!cardFormStatus || isLoading} size='large'>
        <Content className='Profile'>
          <Title level={4} className='mt-3'>Данные для получения банковской карты</Title>
          <Row className='mt-3 mb-3'>
            <Button
              type='text' onClick={() => {
                setExtState(PROFILE_CARD_STATE_INIT)
                history.goBack()
              }}
            >
              <LeftOutlined /> {profileContractScreen.button.back}
            </Button>
          </Row>
          {cardFormStatus === cardStatusCodes.verification
            ? <CheckingData />
            : cardFormStatus !== cardStatusCodes.accepted &&
              <div>
                {
                  cardFormStatus === cardStatusCodes.declined &&
                    <Alert
                      message={<Text type='secondary'>Причины отказа</Text>}
                      description={<div className='pre-line'>{editCardFormData?.form?.statusReason}</div>}
                      type='error'
                    />
                }
                <PageHeader title={profileScreen.title} />
                <Form
                  {...formLayout}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={handleUpdateForm}
                  form={form}
                  layout='vertical'
                  scrollToFirstError
                >
                  <ErrorBlock />
                  <NameFormItem
                    name={['personalData', 'surname']}
                    placeholder={profileScreen.input.surname.placeholder}
                    rules={[{ required: true, message: profileScreen.input.surname.message, validator: validateName }]}
                    label={profileScreen.input.surname.label}
                  />
                  <NameFormItem
                    name={['personalData', 'firstName']}
                    placeholder={profileScreen.input.firstName.placeholder}
                    rules={[{ required: true, message: profileScreen.input.firstName.message, validator: validateName }]}
                    label={profileScreen.input.firstName.label}
                  />
                  <MiddleNameFormItem form={form} trigger={trigger} />
                  <BirthDateFormItem />
                  <PassportBlock />
                  <PlaceOfBirthFormItem requiredRule />

                  <CityFormItem
                    rules={[{ required: true, message: profileScreen.input.city.message }]}
                    onChange={handleChangeCity}
                    withOnDemandStores
                  />

                  <StoreChainFormItem
                    city={internalState?.city}
                    rules={[{ required: true, message: profileScreen.input.storeChain.message }]}
                    disabled={!form.getFieldValue(['personalData', 'city'])}
                    onChange={handleChangeChain}
                    withOnDemandStores
                  />

                  <StoreFormItem
                    city={internalState?.city}
                    chain={internalState?.storeChain}
                    rules={[{ required: true, message: profileScreen.input.store.message }]}
                    disabled={!form.getFieldValue(['personalData', 'storeChain'])}
                    onChange={handleChangeStore}
                    onDemand
                  />

                  <RegistrationAddressBlock
                    initRegistrationKind={internalState.registrationKindCode}
                    registrationFlow={profile?.registrationFlow}
                    required={{
                      zipCode: true,
                      country: true,
                      region: true,
                      street: true,
                      house: true
                    }}
                    disabled={{ country: true }}
                  />

                  <ExistsNfc
                    form={form}
                    checked={internalState?.existsNfc}
                    onChange={handleChangeNfc}
                    required={!internalState?.existsNfc}
                  />

                  <Documents
                    trigger={trigger}
                    form={form}
                    required={{
                      passportPhoto1: true,
                      passportPhoto2: true,
                      passportPhotoTmpReg: profile?.registrationFlow && internalState.registrationKindCode === AppConfig.registrationTypes.temporary,
                      passportPhoto3: true
                    }}
                    documents={[
                      'passportPhoto1',
                      'passportPhoto2',
                      'passportPhotoExtraReg',
                      ...(profile?.registrationFlow && internalState.registrationKindCode === AppConfig.registrationTypes.temporary ? ['passportPhotoTmpReg'] : []),
                      'passportPhoto3'
                    ]}
                    onUpdate={handleUpdateImage}
                    onDelete={handleDeleteImage}
                    onPhotoDoc={() => {
                      setExtState({
                        form: form.getFieldsValue(),
                        storeData: internalState
                      })
                    }}
                  />
                  {[cardStatusCodes.canCreate, cardStatusCodes.declined].includes(profile?.cardFormStatus) && (
                    <Button
                      htmlType='submit' type='primary' size='large' className='mt-3' block
                      loading={isLoading}
                      disabled={!isTouched}
                    >Отправить
                    </Button>
                  )}
                  <Button block size='large' className='mt-3' onClick={() => history.goBack()} disabled={isLoading}>Отмена</Button>
                </Form>
              </div>}
        </Content>
      </Spin>
    </Layout>
  )
}

export default ProfileEditCardScreen
