import React, { useEffect, useMemo, useReducer } from 'react'
import { Form, Select, Radio, Space, Typography, Button } from 'antd'
import { profileScreen } from '../../../../translates'
import { AppConfig } from '../../../../AppConfig'
import './LegalFormItem.css'
import ModalDrawerPopup from '../../ModalDrawerPopup/ModalDrawerPopup'
import { simpleReducer } from '../../../../helper'

const { Title } = Typography
const { Option } = Select
const initialState = {
  isOpen: false,
  value: null
}

function LegalFormItem ({
  form,
  profile,
  isSuccess = false,
  isWarning = false,
  disabled = false,
  label = profileScreen.input.legalForm.label
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const icon = useMemo(() => {
    return (
      <div className='legal-select-icon'>
        {isSuccess && <img src='/img/checkCircle.png' className='mr-3' />}
        {isWarning && <img src='/img/exclamationCircle.png' className='mr-3' />}
        <img src='/img/down-icon.png' />
      </div>
    )
  }, [])

  const handleClickLegal = (e) => {
    if (!disabled) {
      setState({ isOpen: true })
    }
  }

  const handleChangeRadio = (e) => {
    setState({ value: e.target.value })
    form.setFieldsValue({
      personalData: {
        [profileScreen.input.legalForm.name]: e.target.value
      }
    })
  }

  const handleClose = () => {
    setState({ isOpen: false })
  }

  const handleOk = () => {
    setState(initialState)
  }

  useEffect(() => {
    const legalForm = form?.getFieldValue(['personalData', profileScreen.input.legalForm.name])
    if (legalForm) {
      setState({ value: legalForm })
    }
  }, [state.isOpen])

  return (
    <>
      <Form.Item
        name={['personalData', profileScreen.input.legalForm.name]}
        label={label}
        rules={[{ required: profile?.personalData?.required, message: profileScreen.input.legalForm.message }]}
      >
        <Select
          size='large'
          placeholder={profileScreen.input.legalForm.placeholder}
          onClick={handleClickLegal}
          suffixIcon={icon}
          dropdownStyle={{ display: 'none' }}
          disabled={disabled}
        >
          {
            AppConfig.legalForms.map(c => <Option key={c} value={c}>{c}</Option>)
          }
        </Select>
      </Form.Item>
      <ModalDrawerPopup
        className='legal-form-popup'
        isOpen={state.isOpen}
        onClose={handleClose}
        maskClosable
        destroyOnClose
        drawerHeight={250}
      >
        <Title level={3}>{label}</Title>
        <Radio.Group value={state.value} onChange={handleChangeRadio}>
          <Space direction='vertical'>
            {
              AppConfig.legalForms.map(c => <Radio key={c} value={c}>{c}</Radio>)
            }
          </Space>
        </Radio.Group>
        <Button type='success' onClick={handleOk}>Продолжить</Button>
      </ModalDrawerPopup>
    </>
  )
}

export default LegalFormItem
