import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function InnPhoto0 () {
  return (
    <div className='inn-banks'>
      <Title level={3}>ИНН в приложении банка</Title>
      <Text>Как его найти:</Text>
      <ul>
        <li>Откройте приложение вашего банка</li>
        <li>Перейдите в раздел «Документы»</li>
        <li>Найдите строку «ИНН» и скопируйте номер</li>
      </ul>
      <br />
      <div className='relative'>
        <img src='/img/photo-doc/inn-doc-0-t.png' className='img-doc t' />
        <img src='/img/photo-doc/inn-doc-0-s.png' className='img-doc sber' />
        <img src='/img/photo-doc/inn-doc-0-a.png' className='img-doc alfa' />
      </div>
    </div>
  )
}

export default InnPhoto0
