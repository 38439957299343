import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Layout, Button, Typography, PageHeader, Spin, message, Row, Form, Checkbox } from 'antd'

import './Act.css'
// import MobileHeader from '../MobileHeader/MobileHeader'
import { useParams } from 'react-router-dom'
import {
  useGetPartnerActQuery,
  useMutatePartnerActAccept
} from '../../../api/react-query/partners'
import { actScreen } from '../../../translates'
import moment from 'moment-timezone'
import { digestMessage } from '../../../helper'
import { formLayout } from '../../../Constants'

const { Paragraph, Title, Text } = Typography

const Act = () => {
  const [actData, setActData] = useState({})
  const [validityFrom, setValidityFrom] = useState()
  const [validityTo, setValidityTo] = useState()
  const { token } = useParams()

  const {
    data: dataPartnerAct,
    isError: isErrorPartnerAct,
    isSuccess: isSuccessPartnerAct,
    isLoading: isLoadingPartnerAct
  } = useGetPartnerActQuery(token, {
    enabled: !!token
  })
  const {
    mutate: mutatePartnerActAccept,
    isError: isErrorPartnerActAccept,
    isSuccess: isSuccessPartnerActAccept,
    isLoading: isLoadingPartnerActAccept
  } = useMutatePartnerActAccept()

  const acceptAct = useCallback(async (act, templateData) => {
    const actHash = await digestMessage(act.type + act.text)
    mutatePartnerActAccept({ actHash, token, templateData })
  }, [mutatePartnerActAccept, token])

  useEffect(() => {
    if (isErrorPartnerAct) {
      message.error(actScreen.errors.act)
    }
    if (isSuccessPartnerAct && dataPartnerAct?.isSuccess) {
      setActData(dataPartnerAct)
      setValidityFrom(moment(dataPartnerAct.offer.validity_from).format('DD.MM.yyyy'))
      setValidityTo(moment(dataPartnerAct.offer.validity_to).format('DD.MM.yyyy'))
      // autoAccept - автоподтверждение акта(списание)
      // isAccepted - акт согласован
      if (!dataPartnerAct.act.isAccepted && dataPartnerAct.autoAccept) {
        acceptAct(dataPartnerAct.act, dataPartnerAct.templateData)
      }
    }
  }, [
    acceptAct,
    dataPartnerAct,
    isErrorPartnerAct,
    isSuccessPartnerAct
  ])

  useEffect(() => {
    if (isErrorPartnerActAccept) {
      message.error(actScreen.errors.actAccept)
    }
    if (isSuccessPartnerActAccept) {
      message.success(actScreen.success)
    }
  }, [
    isErrorPartnerActAccept,
    isSuccessPartnerActAccept
  ])

  const myRef = useRef(null)
  useEffect(() => {
    if (myRef.current) {
      document.body.scrollIntoView({ behavior: 'smooth' })
    }
  }, [myRef])
  const [form] = Form.useForm()
  const [requiredMark, setRequiredMarkType] = useState('required')

  const onRequiredTypeChange = ({ requiredMark }) => {
    setRequiredMarkType(requiredMark)
  }
  const onFinish = values => {
    if (!values.accept) return
    acceptAct(actData.act, actData.templateData)
  }

  return (
    <Layout className='Act' ref={myRef}>
      {/* <MobileHeader /> */}
      {isErrorPartnerAct ? (
        <Text type='secondary' className='mt-3'>{actScreen.errors.emptyTitle}</Text>
      ) : (
        <Spin spinning={isLoadingPartnerAct || isLoadingPartnerActAccept} size='large'>
          {actData.act?.text && (
            <Form
              {...formLayout}
              name='basic'
              onFinish={onFinish}
              form={form}
              layout='vertical'
              initialValues={{ requiredMark, remember: true }}
              onValuesChange={onRequiredTypeChange}
              requiredMark={requiredMark}
            >
              <Row justify='space-around'>
                <PageHeader title={`${actData.act.type} ${actScreen.prefixBy} ${moment(actData.act.createdAt).format('DD.MM.yyyy')}`} />
              </Row>
              <PageHeader title={actScreen.subTitle} className='title' />
              <Title level={4} className='ml-3'>
                {`${actScreen.prefixFrom} ${validityFrom} ${actScreen.prefixTo} ${validityTo}`}
              </Title>

              <Paragraph className='document'>{actData.act.text}</Paragraph>

              {(!actData.act.isAccepted && !actData.autoAccept) && (
                <>
                  <Form.Item
                    name='accept'
                    valuePropName='checked'
                    rules={[{
                      required: true,
                      transform: value => (value || undefined),
                      type: 'boolean',
                      message: actScreen.errors.acceptTitle
                    }]}
                  >
                    <Checkbox>
                      {actScreen.acceptTitle}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size='large'
                      type='primary'
                      htmlType='submit'
                      className='continue-button'
                    >
                      {actScreen.button.submit}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form>
          )}
        </Spin>
      )}
    </Layout>
  )
}
export default Act
