import React, { useEffect, useReducer } from 'react'
import { Button, message, Spin } from 'antd'
import { successScreen } from '../../../translates'
import { simpleReducer } from '../../../helper'
import './ModalShopperDownload.css'
import { useGetShopperLinks } from '../../../api/react-query/partners'
import ModalDrawerPopup from '../../Common/ModalDrawerPopup/ModalDrawerPopup'

const initialState = {
  isOpen: false,
  android: null,
  ios: null,
  huawei: null,
  rustore: null
}

function ModalShopperDownload ({
  buttonText = successScreen.button.shopperDownload
}) {
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: shopperLinks,
    isLoading: isLoadingShopperLinks,
    isError: isErrorShopperLinks,
    isSuccess: isSuccessShopperLinks
  } = useGetShopperLinks()

  useEffect(() => {
    if (isSuccessShopperLinks && shopperLinks?.isSuccess) {
      setState({
        android: shopperLinks.android,
        ios: shopperLinks.ios,
        huawei: shopperLinks.huawei,
        rustore: shopperLinks.rustore
      })
    } else if (isErrorShopperLinks || shopperLinks?.errorMessage) {
      message.error(shopperLinks?.errorMessage || 'Ошибка получения данных о приложении')
    }
  }, [shopperLinks, isSuccessShopperLinks, isErrorShopperLinks])

  const handleShopperApp = () => {
    setState({
      isOpen: true
    })
  }

  const handleClose = () => setState({ isOpen: false })
  const handleClick = (link) => {
    window.open(link, '_blank', 'noreferrer')
  }

  const getContent = () => (
    <Spin spinning={isLoadingShopperLinks}>
      {state?.android && (
        <Button className='app-button' onClick={() => handleClick(state.android)}>
          <img src='/img/google-app-icon.png' className='icon' />
          <img src='/img/google-app-text.png' />
        </Button>
      )}
      {state?.ios && (
        <Button className='app-button' onClick={() => handleClick(state.ios)}>
          <img src='/img/apple-app-icon.png' className='icon' />
          <img src='/img/apple-app-text.png' />
        </Button>
      )}
      {state?.huawei && (
        <Button className='app-button' onClick={() => handleClick(state.huawei)}>
          <img src='/img/huawei-app-icon.png' className='icon' />
          <img src='/img/huawei-app-text.png' />
        </Button>
      )}
      {state?.rustore && (
        <Button className='app-button' onClick={() => handleClick(state.rustore)}>
          <img src='/img/ru-store-app.png' />
        </Button>
      )}
    </Spin>
  )

  return (
    <>
      <Button
        ghost
        block
        size='large'
        type='success'
        onClick={handleShopperApp}
        className='margin-top-30'
      >
        {buttonText}
      </Button>
      <ModalDrawerPopup
        isOpen={state.isOpen}
        onClose={handleClose}
        destroyOnClose
        maskClosable
        className='modal-shopper-download'
        drawerClassName='drawer-container'
        modalClassName='modal-container'
      >
        {getContent()}
      </ModalDrawerPopup>
    </>
  )
}

export default ModalShopperDownload
