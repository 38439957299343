import React from 'react'
import { Typography } from 'antd'

const { Text, Title } = Typography

function InnPhoto3 () {
  return (
    <>
      <Title level={3}>Перейдите на сайт ФНС</Title>
      <Text>Введите свой ИНН или ОГРНИП и нажмите кнопку «Найти»</Text>
      <br />
      <br />
      <img src='/img/photo-doc/inn-doc-3.png' className='img-doc' />
    </>
  )
}

export default InnPhoto3
