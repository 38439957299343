import React, { useCallback, useEffect, useState } from 'react'
// import MobileHeader from '../../../components/Partner/MobileHeader/MobileHeader'
import { Alert, Button, Col, Form, Input, Layout, message, Row, Spin, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import ErrorBlock from '../../../components/Partner/Profile/ErrorBlock'
import { partnerStatusCodes, fireBriefingStatusCodes } from '../../../AppConfig'
import { profileScreen } from '../../../translates'
import {
  toServerUtcDate,
  createFormDataFromApi
} from '../../../helper'
import './ProfileEditInfoScreenStyle.css'
import '../../../components/Partner/Profile/ProfileStyle.css'
// import { LeftOutlined } from '@ant-design/icons'
import { useStores } from '../../../Store/Store'
import {
  useGetEditFireBriefingForm,
  useMutatePostFireBriefingForm
} from '../../../api/react-query/partners'
import { formLayout } from '../../../Constants'
import { PROFILE_MAIN_PAGE } from '../../../routes'
import FireBriefingDateItem from '../../../components/Common/FormItems/FireBriefingDateItem'
import CheckingData from '../../../components/Partner/CheckingData'

const { Content } = Layout
const { Title, Text } = Typography

function ProfileEditFireBriefingScreen () {
  const {
    partnerProfile: {
      profile,
      profile: {
        fireBriefingFormStatus
      },
      setPartnerProfile
    }
  } = useStores()
  const history = useHistory()
  const [form] = Form.useForm()
  const [isTouched, setIsTouched] = useState(false)
  const [backendFieldsErrors, setBackendFieldsErrors] = useState({})

  const {
    data: editFireBriefingFormData
  } = useGetEditFireBriefingForm({
    enabled: [
      fireBriefingStatusCodes.canCreate,
      fireBriefingStatusCodes.declined,
      fireBriefingStatusCodes.verification
    ].includes(fireBriefingFormStatus)
  })

  const { mutate, data, isLoading, isSuccess, isError } = useMutatePostFireBriefingForm()

  const handleUpdateForm = useCallback((isFirstLaunch = false) => {
    if (!isTouched && isFirstLaunch !== true) {
      setIsTouched(true)
    }
  }, [isTouched, profile?.personalData])

  const fillForm = useCallback((formData) => {
    if (Object.keys(formData?.form || {}).length && !form.getFieldValue(['fireBriefingDetails', profileScreen.input.briefingDate.name])?.length) {
      const data = createFormDataFromApi({
        fireBriefingDetails: {
          ...formData.form
        }
      })
      form.setFieldsValue(data)
    }
  }, [form])

  useEffect(() => {
    fillForm(editFireBriefingFormData)
  }, [editFireBriefingFormData, fillForm])

  useEffect(() => {
    if (isError) {
      message.error('Ошибка отправки данных пожарного инструктажа.')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      if (data?.errorMessage) {
        return message.error(data?.errorMessage || 'Ошибка отправки данных пожарного инструктажа.')
      }
      if (Object.keys(data?.profile || {}).length) {
        setPartnerProfile({ profile: data.profile })
      }
      message.success('Ваш запрос отправлен на проверку.')
    }
  }, [data, isSuccess])

  useEffect(() => {
    if (Object.keys(backendFieldsErrors).length > 0) {
      form.setFields(Object.keys(backendFieldsErrors).map(key => ({
        name: key,
        errors: backendFieldsErrors[key]
      })))
    }
  }, [backendFieldsErrors])

  const onFinish = () => {
    setBackendFieldsErrors({})
    form.validateFields().then(values => {
      mutate({
        data: {
          [profileScreen.input.briefingDate.name]: toServerUtcDate(values?.fireBriefingDetails[profileScreen.input.briefingDate.name]),
          storeId: profile?.personalData?.storeId
        }
      })
    })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const handleBack = () => {
    history.goBack()
  }

  if (profile?.statusCode && profile?.statusCode !== partnerStatusCodes.loginIssued) {
    history.push(PROFILE_MAIN_PAGE)
  }

  return (
    <Layout className='ProfileEditInfoWrapper'>
      {/* <MobileHeader /> */}

      <Spin spinning={!fireBriefingFormStatus || isLoading} size='large'>
        <Content className='Profile'>
          {/* <Title level={4} className='mt-3'>Пожарный инструктаж</Title> */}
          {/* <Row className='mt-3 mb-3'> */}
          {/*  <Button type='text' onClick={handleBack}> */}
          {/*    <LeftOutlined /> {profileContractScreen.button.back} */}
          {/*  </Button> */}
          {/* </Row> */}
          <Row className='mt-3 mb-3 arrow-left'>
            <Button type='text' onClick={handleBack}>
              <img src='/img/arrow-left.png' /> Пожарный инструктаж
            </Button>
          </Row>
          {
            fireBriefingFormStatus === fireBriefingStatusCodes.accepted &&
              <Title level={5} className='mt-4'>Данные пожарного инструктажа уже приняты</Title>
          }
          {fireBriefingFormStatus === fireBriefingStatusCodes.verification
            ? <CheckingData />
            : fireBriefingFormStatus !== fireBriefingStatusCodes.accepted &&
              <div>
                {
                  fireBriefingFormStatus === fireBriefingStatusCodes.declined &&
                    <Alert
                      message={<Text type='secondary'>Причины отказа</Text>}
                      description={<div className='pre-line'>{editFireBriefingFormData?.form?.statusReason}</div>}
                      type='error'
                    />
                }
                {/* <PageHeader title={profileScreen.title} /> */}
                <Form
                  {...formLayout}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onFieldsChange={handleUpdateForm}
                  form={form}
                  layout='vertical'
                  scrollToFirstError
                >
                  <Row className='flex-column'>
                    <Col span={24}>
                      <ErrorBlock />
                      <Form.Item
                        label='Город'
                        className='mt-3'
                      >
                        <Input
                          disabled
                          size='large'
                          value={profile?.personalData?.city}
                        />
                      </Form.Item>
                      <Form.Item
                        label='Сеть'
                      >
                        <Input
                          disabled
                          size='large'
                          value={profile?.personalData?.storeChain}
                        />
                      </Form.Item>
                      <Form.Item
                        label='Магазин'
                      >
                        <Input
                          disabled
                          size='large'
                          value={profile?.personalData?.store}
                        />
                      </Form.Item>
                      {/* <div className='info'>Укажите дату прохождения пожарного инструктажа ниже</div> */}
                      {/* <div className='info'> */}
                      {/*  Город: {profile?.personalData?.city}, Сеть: {profile?.personalData?.storeChain} */}
                      {/* </div> */}
                      {/* <div className='info'> */}
                      {/*  Для магазина: {profile?.personalData?.store} */}
                      {/* </div> */}

                      <FireBriefingDateItem required />
                    </Col>
                    <Col span={24}>
                      <Button
                        htmlType='submit' type='primary' size='large' className='mt-3' block
                        loading={isLoading}
                        disabled={!isTouched}
                      >
                        Отправить
                      </Button>
                      <Button block size='large' className='mt-3' onClick={handleBack} disabled={isLoading}>
                        Отмена
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>}
        </Content>
      </Spin>
    </Layout>
  )
}

export default ProfileEditFireBriefingScreen
