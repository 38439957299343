import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { Typography, Button, message, Row, Col } from 'antd'
import { handleMyTax, simpleReducer } from '../../../../helper'
import './TaxServiceStep.css'
import { profileScreen } from '../../../../translates'
import { AppConfig, individual, selfEmployed } from '../../../../AppConfig'
import { flowV2Steps } from '../ProfileV2'
import { useMutateConfirmMyTaxBind, useMutateConfirmMyTaxStatus } from '../../../../api/react-query/partners'
import { useStores } from '../../../../Store/Store'
import { ConfigContext } from '../../../../context/configContext'

const { Title, Text } = Typography
const { smzErrorCodes, smzRequiredActions } = AppConfig

export const taxServiceSteps = {
  fail: 'fail',
  processing: 'processing',
  lastStepSelfEmployed: 'lastStepSelfEmployed',
  lastStepPartner: 'lastStepPartner'

}

const initialState = {
  currentStep: null
}

function TaxServiceStep ({ profile, step, onUpdateProfile, form, setFlowStep }) {
  const { partnerProfile } = useStores()
  const { setConfig } = useContext(ConfigContext)
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataTaxStatus,
    mutate: confirmTaxStatus,
    isSuccess: isSuccessTaxStatus,
    isError: isErrorTaxStatus,
    isLoading: isLoadingTaxStatus
  } = useMutateConfirmMyTaxStatus()

  const {
    data: dataTaxBind,
    mutate: confirmTaxBind,
    isSuccess: isSuccessTaxBind,
    isError: isErrorTaxBind,
    isLoading: isLoadingTaxBind
  } = useMutateConfirmMyTaxBind()

  useEffect(() => {
    if (isSuccessTaxStatus && dataTaxStatus?.isSuccess) {
      message.success('Запрос отправлен')
      if (
        dataTaxStatus?.partner?.profile?.smz?.requiredAction === profile?.smz?.requiredAction &&
        dataTaxStatus?.partner?.profile?.smz?.errorCode === profile?.smz?.errorCode
      ) {
        showMessageError({
          requiredAction: dataTaxStatus?.partner?.profile?.smz?.requiredAction,
          errorCode: dataTaxStatus?.partner?.profile?.smz?.errorCode
        })
      }
      partnerProfile.setPartnerProfile(dataTaxStatus?.partner)
    } else if (isErrorTaxStatus || dataTaxStatus?.errorMessage) {
      message.error(dataTaxStatus?.errorMessage || 'Ошибка запроса проверки статуса')
    }
  }, [isSuccessTaxStatus, isErrorTaxStatus, dataTaxStatus])

  useEffect(() => {
    if (isSuccessTaxBind && dataTaxBind?.isSuccess) {
      if (!dataTaxBind?.partner?.profile?.smz?.errorCode !== smzErrorCodes.requestNotProcessed) {
        message.success('Запрос отправлен')
      }
      partnerProfile.setPartnerProfile(dataTaxBind?.partner)
    } else if (isErrorTaxBind || dataTaxBind?.errorMessage) {
      message.error(dataTaxBind?.errorMessage || 'Ошибка запроса подтверждения партнерства')
    }
  }, [isSuccessTaxBind, isErrorTaxBind, dataTaxBind])

  useEffect(() => {
    setConfig({
      loadingData: {
        isLoadingFlowV2: isLoadingTaxStatus || isLoadingTaxBind,
        loadingText: <>Проверяем данные.<br />Это может занять какое-то время.</>
      }
    })
  }, [isLoadingTaxStatus, isLoadingTaxBind])

  const showMessageError = ({ requiredAction, errorCode }) => {
    if (requiredAction === smzRequiredActions.ConfirmSmz && [
      smzErrorCodes.unregistered,
      smzErrorCodes.partnerUnregistered
    ].includes(errorCode)
    ) {
      message.error({
        content: (
          <>
            <b>Вы пока не встали на учет в качестве самозанятого</b><br />
            Пожалуйста перейдите в приложении «Мой налог» и выполните шаги согласно инструкции
          </>
        ),
        duration: 5
      })
    }
  }

  const onActionSelfEmployed = () => {
    onUpdateProfile({
      info: {
        personalData: {
          [profileScreen.input.legalForm.name]: selfEmployed
        }
      }
    })
  }

  const onActionIndividual = () => {
    form?.setFieldsValue({
      personalData: {
        [profileScreen.input.legalForm.name]: individual
      }
    })
    setFlowStep(flowV2Steps.innStep)
  }

  useEffect(() => {
    setState({ currentStep: step })
  }, [step])

  const content = useMemo(() => {
    const list = {
      [taxServiceSteps.fail]: <TaxServiceFail onActionSelfEmployed={onActionSelfEmployed} onActionIndividual={onActionIndividual} />,
      [taxServiceSteps.processing]: <TaxServiceProcessing />,
      [taxServiceSteps.lastStepSelfEmployed]: <TaxServiceLastStepSelfEmployed onAction={confirmTaxStatus} />,
      [taxServiceSteps.lastStepPartner]: <TaxServiceLastStepPartner onAction={confirmTaxBind} />
    }
    return list[state.currentStep] || null
  }, [state.currentStep])

  return (
    <div className='tax-service-step'>
      {content}
    </div>
  )
}

function TaxServiceFail ({ onActionSelfEmployed, onActionIndividual }) {
  const onClickLater = () => {
    onActionSelfEmployed?.()
  }
  const onIndividual = () => {
    onActionIndividual?.()
  }
  return (
    <Row className='flex-column'>
      <Col>
        <Title level={3}>Не удалось получить данные</Title>
        <ul>
          <li>Если у вас есть статус самозанятого — его можно подтвердить позже</li>
          <li>Если у вас нет статуса самозанятого — получите его в приложении «Мой налог»</li>
          <li>Вы также можете пройти оформление в качестве ИП</li>
        </ul>
        <img src='/img/alert-yellow.png' />
      </Col>
      <Col>
        <Button type='success' onClick={onClickLater}>Подтвердить позже</Button>
        <Button className='btn-my-tax' onClick={handleMyTax}><img src='/img/fns.png' />Перейти в «Мой налог»</Button>
        <Button onClick={onIndividual}>Оформиться как ИП</Button>
      </Col>
    </Row>
  )
}

function TaxServiceProcessing () {
  return (
    <>
      <Title level={3}>Проверяем данные</Title>
      <Text>Обычно это занимает до пяти минут. <br />Пришлём СМС, как закончим</Text>
      <img src='/img/clock.png' className='processing' />
    </>
  )
}

function TaxServiceLastStepSelfEmployed ({ onAction }) {
  const handleAction = () => {
    onAction?.()
  }
  return (
    <>
      <Title level={3}>Остался последний шаг</Title>
      <Text>Зайдите в приложение или на сайт «Мой налог» и получите налоговый статус замозанятого</Text>
      <img src='/img/pencil.png' />
      <Button className='btn-my-tax' onClick={handleMyTax}><img src='/img/fns.png' />Перейти в «Мой налог»</Button>
      <Button type='success' onClick={handleAction}>Подтвердить самозанятость</Button>
    </>
  )
}

function TaxServiceLastStepPartner ({ onAction }) {
  const handleAction = () => {
    onAction?.()
  }
  return (
    <>
      <Title level={3}>Остался последний шаг</Title>
      <Text>Зайдите в приложение или на сайт «Мой налог» и выберите Купер в качестве партнёра</Text>
      <img src='/img/pencil.png' />
      <Button className='btn-my-tax' onClick={handleMyTax}><img src='/img/fns.png' />Перейти в «Мой налог»</Button>
      <Button type='success' onClick={handleAction}>Подтвердить партнёрство</Button>
    </>
  )
}

export default TaxServiceStep
