import React, { useEffect, useReducer, useMemo } from 'react'
import { Button, Col, Modal, Row } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { simpleReducer } from '../../../helper'
import './HelpDocModal.css'
import PassportPhoto1 from './Content/PassportPhoto1'
import PassportPhoto2 from './Content/PassportPhoto2'
import PassportPhotoErrors from './Content/PassportPhotoErrors'
import { profileScreen } from '../../../translates'
import LegalCapacityConfirmation from './Content/LegalCapacityConfirmation'
import TrusteeConfirmation from './Content/TrusteeConfirmation'
import InnPhoto from './Content/InnPhoto'
import InnPhoto2 from './Content/InnPhoto2'
import EgripPhoto from './Content/EgripPhoto'
import EgripPhoto2 from './Content/EgripPhoto2'
import LmkAllowancePhoto from './Content/LmkAllowancePhoto'
import LmkAttestationPhoto from './Content/LmkAttestationPhoto'
import InnPhoto0 from './Content/InnPhoto0'
import InnPhoto3 from './Content/InnPhoto3'
import InnPhoto4 from './Content/InnPhoto4'
import { useStores } from '../../../Store/Store'

const initialState = {
  isOpenModal: false,
  showList: [],
  currentPage: 0
}

function HelpDocModal ({ docList = [] }) {
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { partnerProfile } = useStores()
  const contentList = useMemo(() => {
    return {
      [profileScreen.input.passportPhoto1.name]: [
        <PassportPhoto1 key={profileScreen.input.passportPhoto1.name} />
      ],
      [profileScreen.input.passportPhoto2.name]: [
        <PassportPhoto2 key={profileScreen.input.passportPhoto2.name} />,
        <PassportPhotoErrors key='passportPhotoErrors' />
      ],
      [profileScreen.input.legalCapacityConfirmation.name]: [
        <LegalCapacityConfirmation key={profileScreen.input.legalCapacityConfirmation.name} />
      ],
      [profileScreen.input.trusteeConfirmation.name]: [
        <TrusteeConfirmation key={profileScreen.input.trusteeConfirmation.name} />
      ],
      [profileScreen.input.innPhoto.name]: partnerProfile?.profile?.innDetails?.inn ? [
        <InnPhoto key={profileScreen.input.innPhoto.name} />,
        <InnPhoto2 key='innPhoto2' />,
        <InnPhoto3 key='innPhoto3' />,
        <InnPhoto4 key='innPhoto4' />
      ] : [
        <InnPhoto0 key='innPhoto0' />,
        <InnPhoto key={profileScreen.input.innPhoto.name} />,
        <InnPhoto2 key='innPhoto2' />,
        <InnPhoto3 key='innPhoto3' />,
        <InnPhoto4 key='innPhoto4' />
      ],
      [profileScreen.input.egripPhoto.name]: [
        <EgripPhoto key={profileScreen.input.egripPhoto.name} />,
        <EgripPhoto2 key='egripPhoto2' />
      ],
      [profileScreen.input.lmkAllowancePhoto.name]: [
        <LmkAllowancePhoto key={profileScreen.input.lmkAllowancePhoto.name} />
      ],
      [profileScreen.input.lmkAttestationPhoto.name]: [
        <LmkAttestationPhoto key={profileScreen.input.lmkAttestationPhoto.name} />
      ]
    }
  }, [])

  const handleClick = () => {
    setState({ isOpenModal: true })
  }
  const handleClose = () => {
    setState({ isOpenModal: false, currentPage: 0 })
  }

  useEffect(() => {
    let showList = []
    docList.forEach(doc => {
      if (contentList[doc]) {
        showList = [...showList, ...contentList[doc]]
      }
    })
    setState({ showList })
  }, [docList, contentList])

  const handleNext = () => {
    const nextPage = state.currentPage + 1
    if (nextPage > state.showList.length - 1) {
      handleClose()
      return
    }
    setState({
      currentPage: nextPage
    })
  }

  const getButtonName = useMemo(() => {
    const inputs = profileScreen.input
    if (docList.includes(inputs.passportPhoto1.name) && docList.includes(inputs.passportPhoto2.name)) {
      return 'Какие фото нужны'
    }
    if (docList.includes(inputs.lmkAttestationPhoto.name) && docList.includes(inputs.lmkAllowancePhoto.name)) {
      return 'Какие фото нужны'
    }
    if (docList.includes(inputs.innPhoto.name) && docList.includes(inputs.egripPhoto.name)) {
      return 'Где найти ИНН и ОГРНИП'
    }
    if (docList.includes(inputs.innPhoto.name)) {
      return 'Где найти ИНН'
    }
    if (docList.includes(inputs.egripPhoto.name)) {
      return 'Где найти ЕГРИП'
    }
    if (docList.includes(inputs.legalCapacityConfirmation.name) || docList.includes(inputs.trusteeConfirmation.name)) {
      return 'Какие документы подходят'
    }
    return null
  }, [docList])

  if (!getButtonName) return null

  return (
    <>
      <Button type='link' onClick={handleClick} className='info-link'>
        <InfoCircleOutlined />
        {getButtonName}
      </Button>
      <Modal
        className='HelpDocModal'
        visible={state.isOpenModal}
        onCancel={handleClose}
        footer={null}
        closeIcon={<img src='/img/cross.png' />}
      >
        {
          state.showList[state.currentPage] && (
            <Row justify='space-between' className='flex-column'>
              <Col>{state.showList[state.currentPage]}</Col>
              <Col className='btn-block'>
                <Button type='success' onClick={handleNext} block>
                  {state.currentPage === state.showList.length - 1 ? 'Понятно' : 'Далее'}
                </Button>
              </Col>
            </Row>
          )
        }
      </Modal>
    </>
  )
}

export default HelpDocModal
