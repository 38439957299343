import React from 'react'
import { Typography } from 'antd'

const { Title, Text } = Typography

export default function CheckingData ({ subText = '' }) {
  return (
    <>
      <Title level={3}>Проверяем данные</Title>
      {subText || <Text>Запрос отправлен на проверку.</Text>}
      <br />
      <img src='/img/clock.png' className='processing' />
    </>
  )
}
